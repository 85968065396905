
.circular-portrait {
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.small {
  width: 40px;
  height: 40px;
}

.large {
  width: 60px;
  height: 60px;
}